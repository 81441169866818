import $http from "@/plugins/axios.js"

export default {
    state:{
        permissions:[]
    },
    getters:{
        permissions: state => state.permissions
    },
    actions:{
        async getPermissions({commit} , filterData = {take:15,page:0,name:""}){
            const response = await $http.get(`/permission?take=${filterData.take}&page=${filterData.page}&filter[name]=${filterData.name}`)
            commit("SET_PERMISSIONS", response.data); 
        },

        async createPermission({commit},permissions){
            const response = await $http.post('/permission',permissions)
            commit('ADD_NEW_PERMISSION',response.data.data)
        },

        async updatePermission({commit} , permissions){
            const response = await $http.post(`/permission/${permissions.id}`,permissions)
            commit('UPDATE_PERMISSION',response.data.data)
        },
        async removePermission({commit} , id){
            await $http.delete(`/permission/${id}`)
            commit('REMOVE_PERMISSION',id)
        }
    },
    mutations:{
        SET_PERMISSIONS(state,data){
            state.permissions = data
        },
        ADD_NEW_PERMISSION(state,data){
            state.permissions.data.push(data)
        },
        UPDATE_PERMISSION(state,data){
            state.permissions.data  = state.permissions.data.map(item => item = item.id == data.id ? data : item)
        },
        REMOVE_PERMISSION(state,id){
            state.permissions.data  = state.permissions.data.filter(item=> item.id !== id)
        },
    }
}