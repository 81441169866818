<template>
  <div id="sectionPrint">
    <div class="container">
      <invoice-header :orderInfo="data" :class="hidden ? 'print:flex hidden' : ''" class="mb-5"></invoice-header>
      <div class="section-info">
        <div class="relative text-sm">
          <div class="title font-bold mb-5 absolute -top-3 px-5 right-5 bg-indigo-50">
            {{ sectionTitle }}
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-5 gap-y-0 text-sm print:grid-cols-2">
            <div class="header grid grid-cols-2 rounded-md p-8 border border-gray-300">
              <ul class="">
                <li v-for="(title, index) in dataSectionFirst" :key="index"
                  class="border-b pb-2 font-bold text-sm text-gray-500">
                  {{ title.title }}
                </li>
              </ul>
              <ul class="">
                <li v-for="(cover, index) in dataSectionFirst" :key="index" class="border-b pb-2 font-bold text-gray-500">
                  <span v-if="data[section][cover.key] == null"> - </span>
                  <span v-else>
                    {{ coverStatus(data[section][cover.key]).text }}
                  </span>
                </li>
              </ul>
            </div>
            <div class="header grid grid-cols-2 rounded-md p-8 border border-gray-300">
              <ul class="">
                <li v-for="(title, index) in dataSectionSecond" :key="index"
                  class="border-b pb-2 font-bold text-sm text-gray-500">
                  {{ title.title }}
                </li>
              </ul>
              <ul class="">
                <li v-for="(cover, index) in dataSectionSecond" :key="index"
                  class="border-b pb-2 font-bold text-gray-500">
                  <span v-if="data[section][cover.key] == null"> - </span>
                  <span v-else>
                    {{ coverStatus(data[section][cover.key]).text }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="notes bg-gray-100 mt-3 py-2 px-2 rounded-md text-sm" v-if="data[section].description">
            <span class="block font-bold"> الملاحضات : </span>
            <span class="">
              {{ data[section].description }}
            </span>
          </div>
          <div class="car_body grid grid-cols-2 gap-5 pt-3 w-full">
            <div class="car_type flex items-centes justify-center flex-grow-1">
              <salon class="w-2/3 transform rotate-90" v-if="data.car_body_type == 0" :coverInfo="data.cover" />
              <pickup class="w-2/3 transform rotate-90" v-else-if="data.car_body_type == 1" :coverInfo="data.cover" />
              <van class="w-2/3 transform rotate-90" v-else-if="data.car_body_type == 2" :coverInfo="data.cover" />
              <gxr class="w-2/3 transform rotate-90" v-else-if="data.car_body_type == 3" :coverInfo="data.cover" />
            </div>
            <div class="car-colors flex items-centes justify-center flex-grow-0">
              <img class="w-full" src="@/assets/car_colors.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import invoiceHeader from "./OrderInfo";
import salon from "@/components/cars/salonType.vue";
import pickup from "@/components/cars/pickupType.vue";
import van from "@/components/cars/vanType.vue";
import gxr from "@/components/cars/gxrType.vue";
export default {
  props: {
    data: Object,
    section: String,
    dataSectionFirst: Array,
    dataSectionSecond: Array,
    imgs: Array,
    sectionTitle: String,
    hidden: Boolean,
  },
  components: {
    invoiceHeader,
    salon,
    pickup,
    van,
    gxr,
  },
};
</script>
