var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-8",attrs:{"id":"OrderImage"}},[_c('min-nav'),_c('page-header',{attrs:{"title":"صورة الطلب","icon":"fas fa-file-image"}}),_c('div',{staticClass:"add-form -mt-10 bg-white w-11/12 mx-auto py-4 rounded shadow mb-3"},[_c('div',{staticClass:"container"},[_c('form',{staticClass:"grid grid-cols-1 lg:grid-cols-1 gap-3 relative",attrs:{"action":""}},[_c('get-loader',{staticClass:"absolute inset-0 z-10"}),_c('div',{staticClass:"img-section mb-3"},[_c('div',{staticClass:"upload"},[_c('input',{attrs:{"type":"file","accept":"image/png, image/jpeg"},on:{"change":function($event){return _vm.getImage($event)}}}),_c('i',{staticClass:"fas fa-cloud-upload-alt text-4xl"}),_c('span',{staticClass:"text-xl underline"},[_vm._v(" رفع صورة الطلب ")])]),_c('div',{staticClass:"preview"},[(_vm.showImg == '')?_c('div',{staticClass:"img-title flex-center flex-col"},[_c('i',{staticClass:"fas fa-photo-video text-4xl"}),_c('span',{staticClass:"text-xl font-bold requier"},[_vm._v(" عرض صورة الطلب ")])]):_c('img',{staticClass:"h-60 object-cover",attrs:{"src":_vm.showImg,"alt":""},on:{"click":function($event){return _vm.openModal(_vm.showImg)}}})])]),_c('div',{staticClass:"order_image_controll flex justify-end"},[(_vm.order_image !== '')?_c('button',{class:[
            _vm.postLoader ? 'opacity-60 cursor-not-allowed' : '',
            'btn main-btn ',
          ],attrs:{"disabled":_vm.postLoader},on:{"click":function($event){$event.preventDefault();return _vm.uploadOrderImage()}}},[_vm._v(" رفع صورة الطلب ")]):_vm._e()]),_c('div',{staticClass:"img_viwer grid grid-cols-4 justify-stretch gap-3"},[_vm._l((_vm.imgs),function(img,index){return _c('div',{key:img.size,staticClass:"imga-show w-20 border-2 border-gray-200 border-dashed p-2 relative"},[(!_vm.modalVisible)?_c('span',{staticClass:"w-6 h-6 flex items-center justify-center text-white z-30 rounded-full bg-red-500 absolute top-0 right-0 cursor-pointer",on:{"click":function($event){return _vm.removrImage(img, index)}}},[_c('i',{staticClass:"fas fa-times"})]):_vm._e(),_c('img',{staticClass:"w-full h-full object-contain",staticStyle:{"width":"400%"},attrs:{"src":img.url,"alt":""},on:{"click":function($event){return _vm.openModal(img.url)}}})])}),(_vm.imgs.length < 5)?_c('div',{staticClass:"relative w-full h-60 border-2 border-gray-200 border-dashed flex items-center justify-center"},[_c('input',{staticClass:"absolute inset-0 w-full h-full cursor-pointer opacity-0",attrs:{"type":"file","maxlength":"5","multiple":"","accept":".jpg, .jpeg, .png"},on:{"change":function($event){return _vm.getImgs($event)}}}),_vm._m(0)]):_vm._e()],2),_c('div',{staticClass:"controll flex justify-end col-span-full"},[(_vm.imgs.length !== 0)?_c('button',{class:[
            _vm.postLoader ? 'opacity-60 cursor-not-allowed' : '',
            'btn main-btn',
          ],attrs:{"disabled":_vm.postLoader},on:{"click":function($event){$event.preventDefault();return _vm.add_new_item()}}},[_vm._v(" رفع الصور الاضافية ")]):_vm._e()])],1)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.modalVisible),expression:"modalVisible"}],staticClass:"absolute inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50"},[_c('div',{staticClass:"max-w-2xl p-6 mx-4 bg-white rounded-md shadow-xl"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('h3',{staticClass:"text-2xl"},[_vm._v("Edit Image")]),_c('svg',{staticClass:"w-8 h-8 text-red-900 cursor-pointer",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"},on:{"click":_vm.closeModal}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"}})])]),_c('div',{staticClass:"mt-4"},[_c('img',{ref:"imgRef",staticClass:"modal-image",attrs:{"src":_vm.modalImage,"alt":"Uploaded Image"},on:{"load":_vm.initializeMarkerJs}}),_c('button',{staticClass:"px-6 py-2 ml-2 text-blue-100 bg-blue-600 rounded",on:{"click":_vm.closeModal}},[_vm._v(" Save ")])])])]),_c('router-link',{attrs:{"to":'/print/' + _vm.$route.params.id}},[_c('div',{staticClass:"print-btn w-16 h-16 rounded-full bg-main text-white text-2xl flex items-center justify-center fixed bottom-5 left-5 print:hidden cursor-pointer animate-bounce",on:{"click":function($event){return _vm.printPage()}}},[_c('i',{staticClass:"fas fa-print"})])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"text-xl flex flex-col gap-3 items-center justify-center"},[_c('i',{staticClass:"far fa-images text-5xl text-gray-200"}),_c('span',{staticClass:"text-lg text-gray-700 font-bold"},[_vm._v(" رفع صور أضافيه ")])])
}]

export { render, staticRenderFns }