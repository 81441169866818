<template>
  <div id="ServicesPage" class="pb-8">
    <page-header title="كومبيوتر" icon="fas fa-cogs"></page-header>
    <div
      v-if="checkRole(['store-Computer'])"
      class="add-form -mt-10 bg-white w-11/12 mx-auto py-4 rounded shadow mb-3"
    >
      <div class="container">
        <div class="section-title">
          <span class="text-xl">فحص الكمبيوتر</span>
        </div>
        <form action="" class="grid grid-cols-2 gap-3 relative">
          <get-loader class="absolute inset-0 z-10"></get-loader>
          <div class="input">
            <label for="" class=""> منظومة الايرباك  </label>
            <select
              name=""
              id=""
              v-model.number="postData.air_bag"
              class="field"
            >
              <option value="" selected disabled>حالة الايرباك</option>
              <option value="0">سليم</option>
              <option value="1">غير سليم</option>
              <option value="2">لايوجد</option>
            </select>
          </div>
          <!-- <div class="input">
            <label for="" class=""> منظومة المحرك </label>
            <select
              name=""
              id=""
              v-model.number="postData.control_engine_ecm"
              class="field"
            >
              <option value="" selected disabled>الحالة</option>
              <option value="0">سليم</option>
              <option value="1">يوجد عطل</option>
            </select>
          </div>
          <div class="input">
            <label for="" class=""> منظومة ناقل الحركه </label>
            <select
              name=""
              id=""
              v-model.number="postData.transmission_control_tcm"
              class="field"
            >
              <option value="" selected disabled>الحالة</option>
              <option value="0">سليم</option>
              <option value="1">يوجد عطل</option>
            </select>
          </div>
          <div class="input">
            <label for="" class=""> منظومة الفرامل ومانع الانزلاق  </label>
            <select
              name=""
              id=""
              v-model.number="postData.anti_lock_brake_system"
              class="field"
            >
              <option value="" selected disabled>الحالة</option>
              <option value="0">سليم</option>
              <option value="1">يوجد عطل</option>
            </select>
          </div>
          <div class="input">
            <label for="" class=""> منظومة الاستيرنج </label>
            <select
              name=""
              id=""
              v-model.number="postData.electric_power_steering"
              class="field"
            >
              <option value="" selected disabled>الحالة</option>
              <option value="0">سليم</option>
              <option value="1">يوجد عطل</option>
            </select>
          </div>
          <div class="input">
            <label for="" class=""> منظومة الرادار</label>
            <select
              name=""
              id=""
              v-model.number="postData.radar_control"
              class="field"
            >
              <option value="" selected disabled>الحالة</option>
              <option value="0">سليم</option>
              <option value="1">يوجد عطل</option>
            </select>
          </div>
          <div class="input">
            <label for="" class=""> منظومة النقطه العمياء </label>
            <select
              name=""
              id=""
              v-model.number="postData.blind_spot_control"
              class="field"
            >
              <option value="" selected disabled>الحالة</option>
              <option value="0">سليم</option>
              <option value="1">يوجد عطل</option>
            </select>
          </div>
          -->
          <div class="input mb-4 col-span-full">
            <label for="" class=""> الملاحضات</label>
            <textarea
              placeholder="وصــف ...."
              v-model="postData.description"
              rows="6"
              class="field"
            ></textarea>
          </div>
 
          <div class="controll flex justify-end col-span-full">
            <button
              :class="[
                postLoader ? 'opacity-60 cursor-not-allowed' : '',
                'btn main-btn',
              ]"
              :disabled="postLoader"
              @click="add_new_item()"
            >
              <span v-if="typeRequest == 'post'"> اضافة </span>
              <span v-else> تعديل </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      typeRequest: "post",
      postData: {
        air_bag:0,
        description: "",
        supplemental_restraint_system:'',
        control_engine_ecm:'',
        transmission_control_tcm:'',
        anti_lock_brake_system:'',
        electric_power_steering:'',
        radar_control:"",
        blind_spot_control:"",
      },
    };
  },
  methods: {
    add_new_item() {
      if (this.typeRequest == "post") {
        this.createComputer(this.postData).then(() => {
          this.$router.push("/computer-list");
        });
      } else {
        this.updateComputer(this.postData).then(() => {
          this.$router.push("/computer-list");
        });
      }
    },
  },
  mounted() {
    this.postData.order_id = this.$route.params.id;
    this.getOrder({
      id: this.postData.order_id,
      include: "computer",
    }).then(() => {
      if (this.order.computer) {
        this.typeRequest = "edit";
        // this.postData.air_bag = this.order.computer.air_bag;
        // this.postData.description = this.order.computer.description;
        // this.postData.id = this.order.computer.id;
        this.postData = this.order.computer;
        this.postData.air_bag = 0;

        this.postData._method = "PUT";
      }
    });
  },
};
</script>
