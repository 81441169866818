import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/HomePage.vue'
import Orders from '../views/Orders/indexPage.vue'
import ArchiveOrder from '../views/Orders/ArchiveOrder.vue'
import PrintPage from '../views/PrintPage.vue'
import PrintPageMaintenance from '../views/MaintenanceOrders/PrintPageMaintenance.vue'
import ShowPrint from '../views/ShowPrint.vue'
import OrderImage from '../views/Orders/OrderImage.vue'
import DainoList from '../views/Daino/DainoList.vue'
import DainoAdd from '../views/Daino/DainoAdd.vue'
import JekList from '../views/Jek/JekList.vue'
import JeckAdd from '../views/Jek/JeckAdd.vue'
import ComputerList from '../views/Computer/ComputerList.vue'
import ComputerAdd from '../views/Computer/ComputerAdd.vue'
import CoverList from '../views/Cover/CoverList.vue'
import CoverCheck from '../views/Cover/CoverCheck.vue'
import Users from '../views/UsersPage.vue'
import Permissions from '../views/PermissionsPage.vue'
import Roles from '../views/RolesPage.vue'
import LoginPage from '../views/LoginPage.vue'
import LayoutPage from '../views/LayoutPage.vue'
import MaintenanceOrders from '../views/MaintenanceOrders/MaintenanceOrdersPage.vue'
import MaintenanceOrderImage from '../views/MaintenanceOrders/MaintenanceOrderImage.vue'
import Accountant from '../views/Accountant/AccountantPage.vue'
import AccountantCalculation from '../views/Accountant/AccountantCalculation.vue'
import LoanRepayment from '../views/Accountant/LoanRepayment.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/invoice/:id',
    name: 'ShowPrint',
    component: ShowPrint
  },
  {
    path: '/LayoutPage',
    name: 'LayoutPage',
    component: LayoutPage,
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home
      },
      {
        path: '/order_image/:id',
        name: 'OrderImage',
        component: OrderImage
      },
      {
        path: '/orders',
        name: 'orders',
        component: Orders
      },
      {
        path: '/archive-order',
        name: 'ArchiveOrder',
        component: ArchiveOrder
      },
      {
        path: '/jek-list',
        name: 'JekList',
        component: JekList
      },

      {
        path: '/jek-add/:id',
        name: 'JeckAdd',
        component: JeckAdd
      },
      {
        path: '/daino-list',
        name: 'DainoList',
        component: DainoList
      },
      {
        path: '/daino-add/:id',
        name: 'DainoAdd',
        component: DainoAdd
      },
      {
        path: '/computer-list',
        name: 'ComputerList',
        component: ComputerList
      },
      {
        path: '/computer-add/:id',
        name: 'ComputerAdd',
        component: ComputerAdd
      },
      {
        path: '/cover-list',
        name: 'CoverList',
        component: CoverList
      },
      {
        path: '/cover-check/:id',
        name: 'CoverCheck',
        component: CoverCheck
      },
      {
        path: '/permissions',
        name: 'Permissions',
        component: Permissions
      },
      {
        path: '/print/:id',
        name: 'print',
        component: PrintPage
      },
      {
        path: '/print-maintenance/:id',
        name: 'print-maintenance',
        component: PrintPageMaintenance
      },
      {
        path: '/roles',
        name: 'Roles',
        component: Roles
      },
      {
        path: '/Users',
        name: 'Users',
        component: Users
      },
      {
        path: '/maintenance_orders',
        name: 'maintenance-orders',
        component: MaintenanceOrders
      },
      {
        path: '/maintenance_orders_image/:id',
        name: 'maintenance-orders_image',
        component: MaintenanceOrderImage
      },
      {
        path: '/accountant/:tab',
        name: 'accountant',
        component: Accountant
      },
      {
        path: '/accountant-calculation',
        name: 'accountant-calculation',
        component: AccountantCalculation
      },
      {
        path: '/loan-repayment/:id',
        name: 'loan-repayment',
        component: LoanRepayment
      },
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
