<template>
    <header>
        <div class="container flex-center">
            <h1 class = "header-title">
                <span class = "top-line"></span>
                <i :class="icon"></i> {{title}}
                <span class = "bottom-line"></span>
            </h1>
        </div>
    </header>
</template>
<script>
export default {
    props:['title' ,"icon"],
    mounted(){
        setTimeout(() =>{
            if(this.$route.path !== '/'){
                document.querySelector('.header-title').classList.add('active')
            }
        },500)
    }
}
</script>