import $http from "@/plugins/axios.js"

export default {
    state:{
        employees:[],
        departments:[],
        employee:{},
        employees_salary:[],
        punishments:[],
        financials:[],
        loans:[],
        loan:[],
        loans_repayment:[],
        rewards:[],
        expanses:[],
        encome:[]
    },
    getters:{
        employees: state => state.employees,
        departments: state => state.departments,
        punishments: state => state.punishments,
        financials: state => state.financials,
        loans: state => state.loans,
        loan: state => state.loan,
        loans_repayment: state => state.loans_repayment,
        rewards: state => state.rewards,
        employee: state => state.employee,
        employees_salary: state => state.employees_salary,
        expanses: state => state.expanses,
        encome: state => state.encome,
    },
    actions:{ 

        async getEmployees({commit}){
            const response = await $http.get(`/user?take=300`)
            commit("SET_EMPLOYEES", response.data);
        },
        async getExpanses({commit}){
            const response = await $http.get(`/expanses`)
            commit("SET_EXPANSES", response);
            return response;


        },
        async getEncome({commit}){
            const response = await $http.get(`/encome`)
            commit("SET_ENCOME", response);
            return response;

        },
        async getEmployeesData({commit}, filterData = {take:15,page:1,name:''}){
            const response = await $http.get(`/user?take=${filterData.take}&page=${filterData.page}&filter[name]=${filterData.name}`)
            commit("SET_EMPLOYEES", response.data);
        },
        async getDepartments({commit}){
            const response = await $http.get(`/department?take=300`)
            commit("SET_DEPARTMENTS", response.data);
        },
        async createSalary({commit},salary){
            await $http.post('/employee-salary',salary)
            return commit
        },
        async changeEmployeeSalary({commit} , data){
            await $http.post(`/employee-salary/${data.id}`,data.data)
            return commit
        },

        async getPunishments({commit},filterData = {take:15,page:1}){
            const response = await $http.get(`/punishment?take=${filterData.take}&page=${filterData.page}&include=employee`)
            commit("SET_PUNISHMENTS", response.data);
        },
        async deletePunishments({commit} , id){
            await $http.delete(`/punishment/${id}`)
            commit('REMOVE_PUNISHMENTS',id)
        },
        async getFinancials({commit},filterData = {take:15,page:1}){
            const response = await $http.get(`/financial?take=${filterData.take}&page=${filterData.page}&include=employee`)
            commit("SET_FINANCIALS", response.data);
        },
        async deleteFinancials({commit} , id){
            await $http.delete(`/financial/${id}`)
            commit('REMOVE_FINANCIALS',id)
        },
        async deleteLoans({commit} , id){
            await $http.delete(`/loan/${id}`)
            commit('REMOVE_LOANS',id)
        },
        async getLoans({commit},filterData = {take:15,page:1}){
            const response = await $http.get(`/loan?take=${filterData.take}&page=${filterData.page}&include=employee&sort=-created_at`)
            commit("SET_LOANS", response.data);
        },
        async getLoansId({commit},data){
            const response = await $http.get(`/loan?filter[id]=${data.id}&include=employee`)
            commit("SET_LOAN", response.data);
        },
        async getLoansRepayments({commit} , data ){
            const response = await $http.get(`/loan-repayment?filter[loan_id]=${data.id}&include=employee&sort=-created_at`) 
            commit("SET_LOANS_REPAYMENT", response.data);
        },
        async createPunishments({commit},amount){
            await $http.post('/punishment',amount)
            return commit
        },
        async createFinancials({commit},amount){
            await $http.post('/financial',amount)
            return commit
        },
        async createLoans({commit},amount){
            await $http.post('/loan',amount)
            return commit
        },
        async createLoansRepayments({commit},amount){
            await $http.post('/loan-repayment',amount)
            return commit
        },
        async getRewards({commit},filterData = {take:15,page:1}){
            const response = await $http.get(`/reward?take=${filterData.take}&page=${filterData.page}&include=employee`)
            commit("SET_REWARDS", response.data);
        },
        
        async deleteRewards({commit} , id){
            await $http.delete(`/reward/${id}`)
            commit('REMOVE_REWARDS',id)
        },
        async createRewards({commit},amount){
            await $http.post('/reward',amount)
            return commit
        },

    },
    mutations:{
        SET_EMPLOYEES(state,data){
            state.employees = data
        },
        SET_ENCOME(state,data){
            state.encome = data
        },
        SET_EXPANSES(state,data){
            state.expanses = data
        },
        
        SET_DEPARTMENTS(state,data){
            state.departments = data
        },
        SET_PUNISHMENTS(state,data){
            state.punishments = data
        },
        SET_FINANCIALS(state,data){
            state.financials = data
        },
        SET_LOANS(state,data){
            state.loans = data
        },
        SET_LOAN(state,data){
            state.loan = data
        },
        SET_LOANS_REPAYMENT(state,data){
            state.loans_repayment = data
        },
        SET_REWARDS(state,data){
            state.rewards = data
        },
        // ADD_NEW_ORDER(state,data){
        //     state.orders.data.push(data)
        // },
        UPDATE_ORDER(state,data){
            state.orders.data  = state.orders.data.map(item => item = item.id == data.id ? data : item)
        },
        REMOVE_ORDER(state,id){
            state.orders.data  = state.orders.data.filter(item=> item.id !== id)
        },
        REMOVE_PUNISHMENTS(state,id){
            state.punishments.data  = state.punishments.data.filter(item=> item.id !== id)
        },
        REMOVE_REWARDS(state,id){
            state.punishments.data  = state.punishments.data.filter(item=> item.id !== id)
        },
        REMOVE_FINANCIALS(state,id){
            state.punishments.data  = state.punishments.data.filter(item=> item.id !== id)
        },
        REMOVE_LOANS(state,id){
            state.punishments.data  = state.punishments.data.filter(item=> item.id !== id)
        },
        // REMOVE_ORDER_ORDER(state,id){
        //     state.order.images  = state.order.images.filter(item=> item.id !== id)
        // },
        
    }
}