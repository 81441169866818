
import $http from "@/plugins/axios.js"


export default {
    state:{
        userInfo:{},
        user_permissions:JSON.parse(localStorage.getItem('car_user_permissions')) || []
    },
    getters:{
        userInfo: state =>  state.userInfo,
        user_permissions: state =>  state.user_permissions,
    },
    actions:{
        async login({commit},data){
            const response = await $http.post('/auth/login',data)
            localStorage.setItem("car_user_Info", JSON.stringify(response.data));
            localStorage.setItem("car_user_permissions", JSON.stringify(response.data.permissions));
            commit('SET_PERMISSIONS', response.data.permissions) 
            commit('SET_USER', response.data) 
        },
        async logOut({commit}){
            const response = await $http.post('/auth/logout')
            localStorage.removeItem("car_user_Info", JSON.stringify(response.data));
            return commit 
        },
    },
    mutations:{
        SET_USER(state,data){
            state.userInfo = data
        },
        SET_PERMISSIONS(state,data){
            state.user_permissions = data
        }
    }
}