<template>
  <div id="ServicesPage" class="pb-8">
    <min-nav></min-nav>
    <page-header title="دايــنو" icon="fas fa-cogs"></page-header>
    <div
      v-if="checkRole(['store-Daino'])"
      class="add-form -mt-10 bg-white w-11/12 mx-auto py-4 rounded shadow mb-3"
    >
      <div class="container">
        <div class="section-title">
          <span class="text-xl">فحص الداينو</span>
        </div>
        <form action="" class="grid grid-cols-2 gap-3 relative">
          <get-loader class="absolute inset-0 z-10"></get-loader>
          <div class="input">
            <label for="" class=""> دبل </label>
            <input
              type="text"
              placeholder="دبل"
              v-model="postData.dabbel"
              class="field"
            />
          </div>
          <!-- <div class="input">
            <label for="" class=""> أيرباك </label>
            <input
              type="text"
              placeholder="أيرباك"
              v-model="postData.air_bag"
              class="field"
            />
          </div> -->
          <div class="input">
            <label for="" class=""> عزم المحرك </label>
            <input
              type="text"
              placeholder="عزم المحرك"
              v-model="postData.engine_torque"
              class="field"
            />
          </div>
          <div class="input">
            <label for="" class="requier"> عزم الكير </label>
            <input
              type="text"
              placeholder="عزم الكير "
              v-model="postData.gear_torque"
              class="field"
            />
          </div>
          <div class="input">
            <label for="" class="requier"> خلل الفوريل </label>
            <input
              type="text"
              placeholder="خلل الفور ويل "
              v-model="postData.issue_in_fourweel"
              class="field"
            />
          </div>
          <div class="input">
            <label for="" class="requier"> تأخر في نمرة الكير ؟ </label>
            <input
              type="checkbox"
              class="w-6 h-6"
              v-model="postData.delay_in_nemra"
            />
          </div>
          <div class="input">
            <label for="" class="requier"> طبة في الكير ؟ </label>
            <input
              type="checkbox"
              class="w-6 h-6"
              v-model="postData.taba_in_gear"
            />
          </div>
          <div class="input">
            <label for="" class="requier"> بلنص ؟ </label>
            <input type="checkbox" class="w-6 h-6" v-model="postData.balance" />
          </div>
          <div class="input mb-4 col-span-full">
            <label for="" class="requier"> وصف الحالة </label>
            <textarea
              placeholder="وصــف ...."
              v-model="postData.description"
              rows="6"
              class="field"
            ></textarea>
          </div>

          <div class="controll flex justify-end col-span-full">
            <button
              :class="[
                postLoader ? 'opacity-60 cursor-not-allowed' : '',
                'btn main-btn',
              ]"
              :disabled="postLoader"
              @click="add_new_item()"
            >
              <span v-if="typeRequest == 'post'"> اضافة </span>
              <span v-else> تعديل </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      typeRequest: "post",
      postData: {
        engine_torque: "",
        gear_torque: "",
        issue_in_fourweel: "",
        order_id: "",
        description: "",
        dabbel: "",
        delay_in_nemra: false,
        taba_in_gear: false,
        balance: false,
      },
    };
  },
  methods: {
    add_new_item() {
      if (this.typeRequest == "post") {
        this.createDaion(this.postData).then(() => {
          this.$router.push("/daino-list");
        });
      } else {
        this.updateDaion(this.postData).then(() => {
          this.$router.push("/daino-list");
        });
      }
    },
  },
  mounted() {
    this.postData.order_id = this.$route.params.id;
    this.getOrder({
      id: this.postData.order_id,
      include: "daino",
    }).then(() => {
      if (this.order.daino) {
        this.typeRequest = "edit";
        this.postData = this.order.daino;
        this.postData._method = "PUT";
      }
    });
  },
};
</script>
