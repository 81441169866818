import $http from "@/plugins/axios.js"

export default {
    state:{
        daions:{
            data:[]
        }
    },
    getters:{
        daions: state => state.daions
    },
    actions:{
        async getDaions({commit} , filterData = {take:15,page:1,name:'',}){
            const response = await $http.get(`/order-daino?take=${filterData.take}&page=${filterData.page}&filter[driver_name]=${filterData.name}`)
            commit("SET_DAINOS", response.data);
        },

        async createDaion({commit},daino){
            const response = await $http.post('/daino',daino)
            commit('ADD_NEW_DAINO',response.data.data)
        },

        async updateDaion({commit} , daino){
            const response = await $http.post(`/daino/${daino.id}`,daino)
            commit('UPDATE_DAINO',response.data.data)
        },
        async removeDaion({commit} , id){
            await $http.delete(`/daino/${id}`)
            commit('REMOVE_DAINO',id)
        }
    },
    mutations:{
        SET_DAINOS(state,data){
            state.daions = data
        },
        ADD_NEW_DAINO(state,data){
            state.daions.data.push(data)
        },
        UPDATE_DAINO(state,data){
            state.daions.data  = state.daions.data.map(item => item = item.id == data.id ? data : item)
        },
        REMOVE_DAINO(state,id){
            state.daions.data  = state.daions.data.filter(item=> item.id !== id)
        },
        
    }
}