<template>
  <div id="sectionPrint ">
    <div class="container">
      <invoice-header :orderInfo="data" class="mb-5"></invoice-header>
      <div class="section-info">
        <div class="pt-8 mb-8">
          <div class="rounded-md p-2 border border-gray-300 relative text-sm">
            <div
              class="title font-bold mb-5 absolute -top-3 px-5 right-5 bg-indigo-50"
            >
              تفاصيل الطلب
            </div>
            <div class="header grid grid-cols-2">
              <ul class="">
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  أسم السائق
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  رقم الهاتف
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  حجم السياره
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  نوع السياره
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  موديل السياره
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  رقم السياره
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  لون السياره
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  رقم الشاصي
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  رقم السنوية
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  عداد السرعة
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  السعر
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  الخصم
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  أجمالي المبلغ
                </li>
              </ul>
              <ul class="">
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  {{ data.driver_name }}
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  {{ data.driver_phone }}
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  <span v-if="data.car_body_type == 0"> صالون </span>
                  <span v-else-if="data.car_body_type == 1"> بيكم </span>
                  <span v-else-if="data.car_body_type == 2"> بيكم </span>
                  <span v-else-if="data.car_body_type == 3"> SUV </span>
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  {{ data.car_type }}
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  {{ data.car_model }}
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  {{ data.car_no }}
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  {{ data.car_color }}
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  {{ data.chassis_no }}
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  {{ data.sanoia_no }}
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  {{ data.speedometer }}
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  {{ data.price }}
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  {{ data.discount }}
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  {{ data.price - data.discount }}
                </li>
              </ul>
            </div>
          </div>
          <!-- <div
            class="notes bg-gray-100 mt-3 py-2 px-2 rounded-md text-xs"
            v-if="data[section].description"
          >
            <span class="block font-bold"> الملاحضات : </span>
            <span class="">
              {{ data[section].description }}
            </span>
          </div> -->
          <span class="py-3 font-bold text-md inline-block">
            يخلي المركز مسؤوليته من نتائج التقرير بعد مغادرة السيارة من المركز
          </span>
          <ul
            class="flex items-center justify-center gap-3 section-img pt-3"
            v-if="data.image_url"
          >
            <li class="w-2/5">
              <img
                class="w-full"
                style="max-height: 550px"
                :src="data.image_url"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import invoiceHeader from "./OrderInfo";

export default {
  props: {
    data: Object,
  },
  components: {
    invoiceHeader,
  },
};
</script>
