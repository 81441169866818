<template>
  <div id="OrderImage" class="pb-8">
    <min-nav></min-nav>
    <page-header title="دفعات السلف" icon="fas fa-file-image"></page-header>
    <div class="add-form -mt-10 bg-white w-11/12 mx-auto py-4 rounded shadow mb-3">
      <div class="container">
        <div class="container">
          <div class="section-title">
            <span class="text-xl">أضافة دفعة جديدة</span>
            <span>
              <router-link :to="'/accountant/3'">
                <div class="mt-9 cursor-pointer">
                  <span
                    :class="[
                      postLoader ? 'opacity-60 cursor-not-allowed' : '',
                      'btn sub-btn',
                    ]"
                    :disabled="postLoader"
                  >
                    رجوع
                    <i style="margin-right: 0.5rem" class="fas fa-arrow-left"></i>
                  </span>
                </div>
              </router-link>
            </span>
          </div>
          <div class="section-title text-lg" style="margin-bottom: 2rem">
            <span
              ><span>مقدار السلفة المتبقي للموظف </span
              >{{ this.loan.data[0].employee.name }} <span>هو </span>
              {{ this.loan.data[0].amount }}
            </span>
          </div>
          <form action="" class="grid grid-cols-2 lg:grid-cols-4 gap-2">
            <div class="input mb-4">
              <label for="" class="requier"> مقدار الدفعة </label>
              <input
                type="number"
                placeholder=" ادخل مقدار الدفعة"
                v-model="postLoanRepaymentData.amount"
                class="field"
              />
            </div>
            <div class="mt-9 cursor-pointer">
              <span
                :class="[
                  postLoader ? 'opacity-60 cursor-not-allowed' : '',
                  'btn main-btn',
                ]"
                :disabled="postLoader"
                @click.prevent="addLoanRepayment()"
              >
                أضافة
              </span>
            </div>
          </form>
        </div>
      </div>
      <div class="">
        <div class="container mt-10">
          <div class="section-title">
            <span class="text-xl">قائمة الدفعات السابقة</span>
            <div class="show-per-Page text-sm font-normal">
              <span class=""> عناصر القائمة </span>
              <select
                name=""
                id=""
                v-model="filterData.take"
                class="bg-gray-100 px-2 rounded-md outline-none"
              >
                <option value="2">2</option>
                <option value="15">15</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
              </select>
            </div>
          </div>
          <div class="list relative py-5">
            <get-loader class="absolute inset-0"></get-loader>
            <data-table :fields="loanRepaymentFields" :data="loans_repayment.data">
              <template v-slot:order_no="{ item }">
                {{ item.order_no?.split("-")[4] }}
              </template>
              <template v-slot:name="{ item }">
                {{ item.employee.name }}
              </template>
              <template v-slot:created_at="{ item }">
                {{ getFormDate(item.created_at) }}
              </template>
            </data-table>
          </div>
        </div>
      </div>
      <div
        class="pagination mx-auto py-4 bg-white mt-3"
        v-if="orders.total > filterData.take"
      >
        <div class="container flex items-center justify-center">
          <v-pagination
            v-model="currentPage"
            :records="orders.total"
            :per-page="filterData.take"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style>
header {
  border-radius: 0 0 50% 50%;
}
</style>
<script>
export default {
  data() {
    return {
      imgs: [], // Each item should be { id: string, file: File, annotatedBlob: Blob, type: string, url: string }
      showImg: "",
      order_image: "",
      postData: {
        maintenance_order_id: "",
      },
      filterData: {
        take: 15,
        skip: 0,
        email: "",
        name: "",
      },
      postLoanRepaymentData: {
        amount: "",
        employee_id: "",
        loan_id: "",
        notes: "",
      },
      modalVisible: false, // Control modal visibility
      modalImage: "", // Store the image source for the modal
    };
  },
  computed: {
    loanRepaymentFields() {
      return [
        {
          title: "اسم الموظف",
          key: "name",
        },
        {
          title: "مقدار دفعة السلفة",
          key: "amount",
        },
        {
          title: "تاريخ الدفعة",
          key: "created_at",
        },
      ];
    },
  },
  methods: {
    addLoanRepayment() {
      (this.postLoanRepaymentData.loan_id = this.$route.params.id),
        this.createLoansRepayments(this.postLoanRepaymentData).then(() => {
          this.getLoansRepayments({
            id: this.$route.params.id,
          });
          this.postLoanRepaymentData = {
            amount: "",
            employee_id: "",
            notes: "",
            loan_id: "",
          };
        });
    },
  },
  mounted() {
    this.postData.maintenance_order_id = this.$route.params.id;
    this.getLoansId({
      id: this.$route.params.id,
    }).then(() => {
      this.postLoanRepaymentData.employee_id = this.loan.data[0].employee_id;
    });
    this.getLoansRepayments({
      id: this.$route.params.id,
    });
  },
};
</script>
