<template>
  <div id="ServicesPage" class="pb-8">
    <page-header title="كمبيوتر" icon="fas fa-cogs"></page-header>
    <div
      v-if="checkRole(['change-order-status'])"
      :class="[editStatus ? 'active' : '', 'edit-form']"
    >
      <form
        action=""
        class="grid grid-cols-1 gap-1 w-11/12 md:w-8/12 bg-white rounded px-8 py-5 max-h-full overflow-auto"
      >
        <div class="input mb-4">
          <label for="" class="requier"> قسم الفحص </label>
          <select
            name=""
            id=""
            v-model="changeStatusValue.status"
            class="field"
          >
            <option value="" selected disabled>القسم</option>
            <option value="1">جـــك</option>
            <option value="3">فحص البدي</option>
            <option value="4">مكتمل</option>
          </select>
        </div>

        <div class="controll flex justify-end pt-8">
          <button class="btn sub-btn" @click.prevent="toggleEditStatus()">
            غلق
          </button>
          <button
            :class="[
              postLoader ? 'opacity-60 cursor-not-allowed' : '',
              'btn main-btn',
            ]"
            :disabled="postLoader"
            @click.prevent="edit_item()"
          >
            تعديل الحالة
          </button>
        </div>
      </form>
    </div>
    <div class="list-form" v-if="checkRole(['get-Computer-Orders'])">
      <div class="container">
        <div class="section-title">
          <span class="text-xl">طلبات الكمبيوتر</span>
          <div class="show-per-Page text-sm font-normal">
            <span class=""> عناصر القائمة </span>
            <select
              name=""
              id=""
              v-model="filterData.take"
              class="bg-gray-100 px-2 rounded-md outline-none"
              @change="filtering"
            >
              <option value="15">15</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
            </select>
          </div>
        </div>

        <div class="filters" @change="filtering()">
          <div class="name input">
            <input
              type="search"
              placeholder="أسم السائق"
              class="field"
              v-model="filterData.name"
            />
          </div>
        </div>
        <div class="list relative py-5">
          <get-loader class="absolute inset-0"></get-loader>
          <data-table :fields="fields" :data="computer.data">
            <template v-slot:order_no="{ item }">
              {{ item.order_no?.split("-")[4] }}
            </template>
            <template v-slot:created_at="{ item }">
              {{ getFormDate(item.created_at) }}
            </template>
            <template v-slot:updated_at="{ item }">
              {{ getFormDate(item.updated_at) }}
            </template>
            <template v-slot:actions="{ item }">
              <ul class="actions-list">
                <!-- <li
                  class="text-gray-500 hover:text-red-500"
                  @click="remove_item(item)"
                >
                  <i class="fas fa-trash-alt"></i>
                </li> -->
                <li
                  v-if="checkRole(['change-order-status'])"
                  class="text-gray-500 hover:text-blue-500"
                  @click="editInit(item)"
                >
                  <i class="fas fa-pencil-alt"></i>
                </li>
                <li
                  v-if="checkRole(['store-Computer'])"
                  class="text-gray-500 hover:text-green-500"
                  @click="add_computer(item.id)"
                >
                  <i class="fas fa-plus"></i>
                </li>
              </ul>
            </template>
          </data-table>
        </div>
      </div>
    </div>
    <div
      class="pagination w-11/12 mx-auto py-4 bg-white mt-3"
      v-if="computer.total > filterData.take"
    >
      <div class="container flex items-center justify-center">
        <v-pagination
          v-model="currentPage"
          :records="computer.total"
          :per-page="filterData.take"
        />
      </div>
    </div>
  </div>
</template>
<style>
header {
  border-radius: 0 0 50% 50%;
}
</style>
<script>
export default {
  data() {
    return {
      currentPage: 1,
      postData: {
        order_id: "",
        status: "",
      },
      changeStatusValue: {
        order_id: "",
        status: "",
      },
      filterData: {
        take: 15,
        page: 1,
        name: "",
      },
    };
  },
  computed: {
    fields() {
      return [
        {
          title: "رقم الفاتورة",
          key: "order_no",
        },
        {
          title: "تاريخ",
          key: "created_at",
        },
        {
          title: "أسم السائق",
          key: "driver_name",
        },
        {
          title: "رقم السيارة",
          key: "car_no",
        },
        {
          title: "نوع السيارة",
          key: "car_type",
        },
        {
          title: "لون السيارة",
          key: "car_color",
        },
        {
          title: "أخر تعديل",
          key: "updated_at",
        },
      ];
    },
  },
  watch: {
    currentPage(value) {
      this.filterData.page = value;
      this.getComputers(this.filterData);
    },
  },
  methods: {
    filtering() {
      this.getComputers(this.filterData);
    },
    editInit(item) {
      this.changeStatusInit = true;
      this.changeStatusValue.order_id = item.id;
      this.toggleEditStatus();
    },
    edit_item() {
      this.changeStatusOrder({
        order_id: this.changeStatusValue.order_id,
        data: this.dataReutrn({
          status: this.changeStatusValue.status,
          _method: "PUT",
        }),
      }).then(() => {
        this.toggleEditStatus();
        this.getDaions();
      });
    },
    add_computer(order_id) {
      this.$router.push(`/computer-add/${order_id}`);
    },
  },
  mounted() {
    this.getComputers();
  },
};
</script>
