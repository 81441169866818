import $http from "@/plugins/axios.js"

export default {
    state:{
        roles:[],
        role:{
            permissions:[]
        }
    },
    getters:{
        roles: state => state.roles,
        role: state => state.role
    },
    actions:{
        async getRoles({commit} , filterData = {take:15,page:0,name:""}){
            const response = await $http.get(`/role?take=${filterData.take}&page=${filterData.page}&filter[name]=${filterData.name}`)
            commit("SET_ROLES", response.data);
        },
        async showRole({commit} , id){
            const response = await $http.get(`/role/${id}?include=permissions`)
            commit("SET_ROLE", response.data);
        },

        async updateRole({commit} , role){
            
            const response = await $http.post(`/role/${role.id}`,role)
            commit('UPDATE_ROLE',response.data.data)
        },
        async removeRole({commit} , id){
            await $http.delete(`/role/${id}`)
            commit('REMOVE_ROLE',id)
        },
        async createRole({commit},role){
            const response = await $http.post('/role',role)
            commit('ADD_NEW_ROLE',response.data)
        },
        async assignRole({commit},role){
            await $http.post(`/assign-permission-to/role/${role.id}`,role.permissions)
            return  commit
        },

    },
    mutations:{
        SET_ROLES(state,data){
            state.roles = data
        },
        SET_ROLE(state,data){
            state.role = data
        },
        ADD_NEW_ROLE(state,data){
            state.roles.data.push(data)
        },
        UPDATE_ROLE(state,data){
            state.roles.data  = state.roles.data.map(item => item = item.id == data.id ? data : item)
        },
        REMOVE_ROLE(state,id){
            state.roles.data  = state.roles.data.filter(item=> item.id !== id)
        },
    }
}