<template>
  <div id="sectionPrint ">
    <div class="container">
      <invoice-header
        :orderInfo="data"
        :class="hidden ? 'print:flex hidden' : ''"
        class="mb-5"
      ></invoice-header>
      <div class="section-info">
        <div class="pt-8 mb-8">
          <div class="rounded-md p-2 border border-gray-300 relative text-sm">
            <div
              class="title font-bold mb-5 absolute -top-3 px-5 right-5 bg-indigo-50"
            >
              {{ sectionTitle }}
            </div>
            <div class="header grid grid-cols-2">
              <ul class="">
                <li
                  v-for="(title, index) in dataSection"
                  :key="index"
                  class="border-b pb-2 font-bold text-sm text-gray-500"
                >
                  {{ title.title }}
                </li>
              </ul>
              <ul class="">
                <li
                  v-for="(title, index) in dataSection"
                  :key="index"
                  class="border-b pb-2 text-sm font-bold text-gray-500"
                >
                  <span v-if="title.key == 'air_bag'"> {{ air_bag_status[data[section][title.key]] }} </span>
                  <!-- <span v-else> {{ status[data[section][title.key]] }} </span> -->
                </li>
              </ul>
            </div>
          </div>
          <div
            class="notes bg-gray-100 mt-3 py-2 px-2 rounded-md text-xs"
            v-if="data[section].description"
          >
            <span class="block font-bold"> الملاحضات : </span>
            <span class="">
              {{ data[section].description }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import invoiceHeader from "./OrderInfo";

export default {
  props: {
    data: Object,
    section: String,
    dataSection: Array,
    imgs: Array,
    sectionTitle: String,
    hidden: Boolean,
  },

  data() {
    return {
      air_bag_status: ["سليم", "غير سليم", "لايوجد"],
      // status: ["سليم", "يوجد عطل"],
    };
  },

  components: {
    invoiceHeader,
  },
};
</script>
