<template>
  <div
    id="side_bar"
    :class="[
      sideBarStatus ? 'inset-0' : '-right-64',
      'w-64 bg-white py-4 px-4 shadow fixed inset-0 duration-700 z-40',
    ]"
  >
    <div
      class="sidebar-toggle bg-main text-xl text-white flex-center w-12 h-12 rounded-full absolute -left-16 shadow cursor-pointer"
      @click="toggleSideBarStatus()"
    >
      <i class="fas fa-bars"></i>
    </div>
    <div class="tiltle text-center mb-5">
      <h1 class="text-2xl text-main font-bold">لـوحة التحكم</h1>
      <!-- <span class="block text-gray-400 font-bold">{{ getUserName }}</span>
      <span class="block text-gray-400">{{ getUserEmail }}</span> -->
    </div>
    <div class="sidebar-list">
      <ul>
        <li class="mb-1" v-for="(item, index) in sidebarContent" :key="index">
          <router-link
            v-if="checkRole(item.permissions)"
            exact
            active-class="active"
            :to="item.link"
            class="flex items-center justify-between py-1 text-gray-500 rounded hover:bg-main text-md hover:text-white duration-500 px-2"
          >
            <span>{{ item.title }}</span>
            <span>
              <i :class="item.icon" class="w-10 text-center"></i>
            </span>
          </router-link>
        </li>
        <li class="mb-1 cursor-pointer" @click.prevent="logOutUser()">
          <a
            class="flex items-center justify-between py-1 text-gray-500 rounded hover:bg-main text-md hover:text-white duration-500 px-2"
          >
            <span>تسجيل خروج</span>
            <span>
              <i class="fas fa-sign-out-alt w-10 text-center"></i>
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<style>
@media print {
  #side_bar {
    display: none !important;
  }
}
</style>
<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      sidebarContent: [
        {
          title: "الطلبات",
          link: "/orders",
          icon: "fas fa-home",
          permissions: [],
        },
        {
          title: "أرشيف الطلبات",
          link: "/archive-order",
          icon: "fas fa-archive",
          permissions: ["get-Orders"],
        },
        {
          title: "جـــك",
          link: "/jek-list",
          icon: "fas fa-cogs",
          permissions: ["get-Jek", "get-Jek-Orders", "show-Jek", "store-Jek"],
        },
        {
          title: "داينو",
          link: "/daino-list",
          icon: "fas fa-cogs",
          permissions: ["get-Daino", "get-Daino-Orders", "show-Daino", "store-Daino"],
        },
        {
          title: "كمبيوتر",
          link: "/computer-list",
          icon: "fa fa-solid fa-laptop",
          permissions: [
            "get-Computer",
            "get-Computer-Orders",
            "show-Computer",
            "store-Computer",
          ],
        },
        {
          title: "فحص البدي",
          link: "/cover-list",
          icon: "fas fa-briefcase",
          permissions: ["get-Cover", "get-Cover-Orders", "show-Cover", "store-Cover"],
        },
        // {
        //   title: " صلاحيات المستخدمين",
        //   link: "/permissions",
        //   icon: "fas fa-users",
        //   permissions: [
        //     "get-permission",
        //     "show-permission",
        //     "store-permission",
        //     "update-permission",
        //     "delete-permission",
        //   ],
        // },
        // {
        //   title: " أدوار المستخدمين",
        //   link: "/roles",
        //   icon: "fas fa-users",
        //   permissions: [
        //     "get-role",
        //     "show-role",
        //     "store-role",
        //     "update-role",
        //     "delete-role",
        //     "assign-permission-to-role",
        //   ],
        // },
        {
          title: "المستخدمين",
          link: "/users",
          icon: "fas fa-users",
          permissions: [
            "get-user",
            "show-user",
            "store-user",
            "update-user",
            "delete-user",
          ],
        },
        {
          title: "طلبات الصيانة",
          link: "/maintenance_orders",
          icon: "fas fa-list",
          permissions: [],
        },
        {
          title: "احصائيات الحسابات",
          link: "/accountant-calculation",
          icon: "fas fa-calendar-check",
          permissions: [
            "get-user",
            "show-user",
            "store-user",
            "update-user",
            "delete-user",
          ],
        },
        {
          title: "الحسابات",
          link: "/accountant/1",
          icon: "fas fa-calculator",
          permissions: [
            "get-user",
            "show-user",
            "store-user",
            "update-user",
            "delete-user",
          ],
        },
      ],
    };
  },
  methods: {
    logOutUser() {
      Swal.fire({
        title: "تأكيد العملية",
        text: "سيتم تسجيل الخروج من الحساب الحالي",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "تسجيل الخروج",
        cancelButtonText: "الغاء",
      }).then((result) => {
        if (result.isConfirmed) {
          this.logOut().then(() => {
            this.$router.push("/");
          });
        }
      });
    },
  },
  mounted() {
    if (window.innerWidth < 768) {
      this.toggleSideBarStatus();
    }
  },
};
</script>
