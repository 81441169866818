import $http from "@/plugins/axios.js"

export default {
    state:{
        covers:[]
    },
    getters:{
        covers: state => state.covers
    },
    actions:{
        async getCovers({commit} , filterData = {take:15,page:1,name:'',}){
            const response = await $http.get(`/order-cover?take=${filterData.take}&page=${filterData.page}&filter[driver_name]=${filterData.name}`)
            commit("SET_COVERS", response.data);
        },

        async createCover({commit},cover){
            await $http.post('/cover',cover)
            return commit
        },

        async updateCover({commit} , cover){
            await $http.post(`/cover/${cover.id}`,cover)
            return commit
        },
        async removeCover({commit} , id){
            await $http.delete(`/cover/${id}`)
            commit('REMOVE_COVER',id)
        }
    },
    mutations:{
        SET_COVERS(state,data){
            state.covers = data
        },
        ADD_NEW_COVER(state,data){
            state.covers.data.push(data)
        },
        UPDATE_COVER(state,data){
            state.covers.data  = state.covers.data.map(item => item = item.id == data.id ? data : item)
        },
        REMOVE_COVER(state,id){
            state.covers.data  = state.covers.data.filter(item=> item.id !== id)
        },
        
    }
}